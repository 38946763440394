import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;
const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-paneli-fotowoltaicznych'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing paneli fotowltaicznych, fotowoltaiki'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing paneli fotowoltaicznych, fotowoltaiki</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>
          {/* <div className={s_text.double}>
            <div>
              <p>
                Coraz to więcej przedsiębiorstw stawia na alternatywne, ekologiczne źródło pozyskiwania
                energii, jakim jest instalacja fotowoltaiczna. Rozwiązanie te niesie za sobą ogrom
                korzyści, którym nie sposób nie ulec. Brak kosztów użytkowania, niewytwarzanie zanieczyszczeń
                w trakcie pracy, czy też bezobsługowość to tylko niektóre z gwarantowanych zalet.
              </p>
              <p>
                Decydując się na instalację fotowoltaiczną, mogą Państwo obniżyć koszty prowadzonej
                działalności, a zaoszczędzone pieniądze przeznaczyć na inne cele związane z własnym biznesem.
              </p>
            </div>
            <div>
              <p>
                Jedną z częściej wybieranych form finansowania ogniw fotowoltaicznych jest leasing, który
                pozwala przedsiębiorcom zachować płynność i bezpieczeństwo finansowe, zaoszczędzić cenny
                czas na załatwianiu formalności oraz zmniejszyć ich obciążenia podatkowe.
              </p>
              <p>
                Instalacja fotowoltaiczna, czy korektory słoneczne to mądre i opłacalne przedsięwzięcie
                dla każdego! Chcąc pomóc w jego realizacji, dla moich Klientów przygotowuję indywidualne
                oferty, na najkorzystniejszych oraz odpowiednio dobranych warunkach.
              </p>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Przedmiot oferty</h2>

              <ul className={s_text.checkmark_list}>
                <li>Kompletna instalacja fotowoltaiczna <strong>bez limitu</strong> łącznej mocny wyrażonej w kW</li>
                <li>Panele fotowoltaiczne/kolektory słoneczne o każdej mocy wyrażonej w kW</li>
                <li>Inwerter do instalacji</li>
                <li><strong>Leasing paneli fotowoltaicznych</strong></li>
                <li><strong>Leasing INSTALACJI fotowoltaicznych</strong></li>
                <li><strong>Leasing OGNIW FOTOWOLTAICZNYCH</strong></li>
                <li><strong>Leasing PANELI SŁONECZNYCH</strong></li>
                <li><strong>Leasing KOLEKTORÓW SŁONECZNYCH</strong></li>
              </ul>
            </div>

            <div>
              <h2>Warunki leasingu instalacji fotowoltaicznej</h2>
              <ul className={s_text.dash_list}>
                <li>Czas trwania leasingu do 72 miesięcy</li>
                <li>Wpłata wstępna od 5%</li>
                <li>Raty równe, degresywne, sezonowe</li>
                <li>Minimum formalności oraz uproszczone procedury</li>
                <li>Krótki czas oczekiwania na decyzję</li>
              </ul>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
