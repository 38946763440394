import React from 'react';


import * as s from './handle-loading.module.scss';


const HandleLoading = ({loading, error, children}) => {


    if(loading) return 'Ładowanie...';
    if(error) return 'Wystąpił błąd';

    return children;
};

export default HandleLoading;
