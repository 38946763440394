import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';


import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s from './bread-crumbs.module.scss';

import Home from '@assets/images/icons/home.svg';
import ArrowRight from '@assets/images/icons/arrow_right.svg';


const BreadCrumbs = () => {

    const [crumbs, setCrumbs] = useState([]);

    useEffect(() => {

        let initCrumbs = [];
        let currPath = '';

        window.location.pathname.split('/').forEach(el => {
            if(el !== ''){
                currPath += `/${el}`;
                initCrumbs.push({
                    name: el,
                    path: el === 'oferta' ? '/' : currPath
                });
            }
        });

        setCrumbs(initCrumbs);

    }, []);

    return (
        <section className={`${s.bread_crumbs_section} ${s_wrapper.apply}`}>

            <div className={s.wrapper}>
                <Link className={s.home_link} to='/'>
                    <img src={Home} alt="Home" />
                </Link>

                {crumbs.map((el, id) => (

                    <div key={id} className={s.item}>
                        <img src={ArrowRight} alt="Strzałka" className={s.arrow} />
                        <Link className={s.link} to={el.path}>
                            {el.name}
                        </Link>
                    </div>

                ))}
            </div>

        </section>
    )
};

export default BreadCrumbs;
